import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const seoData = {
  title: 'Página no encontrada',
  description: 'Aquí no hay nada :\'(',
  path: '/404',
}

const Page = () => (
  <Layout subtitle="Aquí no hay nada :'(">
    <SEO title="Página no encontrada" pageData={ seoData } />
    
    <div style={{ width: `100%`, textAlign: `center` }}>
      <Link 
        to="/" 
        style={{ fontSize: `2.5rem`, textDecoration: `none` }}
        className="text-tertiary">
        <strong>Ir al inicio</strong>
      </Link>
    </div>
  </Layout>
)

export default Page
